<template>
  <div>
    <div class="text-header mb-10">Choose a password</div>
    <v-text-field
      label="Choose a password (min 8 chars)"
      rounded
      filled
      color="grey"
      class="py-0 my-0"
      v-model="password"
      dense
      @click:append="showPassword = !showPassword"
      :type="showPassword ? 'text' : 'password'"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      hint="At least 8 characters"
    ></v-text-field>
    <v-text-field
      label="Choose a password (min 8 chars)"
      rounded
      filled
      color="grey"
      class="py-0 my-0"
      v-model="passwordConfirmation"
      dense
      :rules="passwordConfirmationRules"
      @click:append="showPassword = !showPassword"
      :type="showPassword ? 'text' : 'password'"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      hint="At least 8 characters"
    ></v-text-field>
    <v-btn
      class="sign-up-button my-4 white--text"
      rounded
      color="#00b08d"
      large
      block
      @click="signUpByEmail()"
      :disabled="!isFormValid || isLoading"
      :loading="isLoading"
      >Create account
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  created() {
    // set the app bar title
    this.setCurrentAppBarTitle("Save your progress");

    if (!this.emailOrPasswordInput) {
      this.$router.push({ name: "SignUp" });
    }
  },
  data() {
    return {
      password: null,
      passwordConfirmation: null,
      showPassword: false,
      isLoading: false,
    };
  },
  computed: {
    passwordConfirmationRules() {
      return [(v) => v == this.password || "Passwords do not match"];
    },
    isFormValid() {
      return (
        this.password &&
        this.password.length > 8 &&
        this.passwordConfirmation == this.password
      );
    },
    emailOrPasswordInput: {
      get: function () {
        return this.$store.getters["getEmailOrPhoneNumber"];
      },
      set: function (newVal) {
        this.$store.commit("setEmailOrPhoneNumber", newVal);
      },
    },
  },
  methods: {
    ...mapActions(["signUpToFirebaseByEmail"]),
    ...mapMutations(["setCurrentAppBarTitle", "showFeedbackDialogData"]),

    async signUpByEmail() {
      this.isLoading = true;
      try {
        await this.signUpToFirebaseByEmail({
          email: this.emailOrPasswordInput,
          password: this.password,
        });
        this.showFeedbackDialogData({
          title: "Registred successfully",
          description: "Planet Earth thanks you 🌍",
        });
        this.$router.push({ name: "AboutYou" });
      } catch (error) {
        this.$alertsService.fireGeneralError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
